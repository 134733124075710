import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";
import { classNames } from "../../../../utils/helpers/classNames";
import { Countries } from "./components/Countries/Countries";
import { MobileProxy } from "./components/MobileProxy/MobileProxy";
import { Top } from "./components/Top/Top";
import { PriceItemsList } from "./components/PriceItemsList/PriceItemsList";
import "./Prices.scss";

export const Prices = () => {
  // **Props
  const [searchParams] = useSearchParams();
  const s = searchParams.get("s");

  // **Redux state
  const isThemeDark = useIsThemeDark();
  const { activeData } = useSelector((state) => state.content);

  // *Ref
  const refPrices = useRef(null);
  // ** Local state
  const [localProxyType, setLocalProxyType] = useState(activeData?.proxy?.code || null);
  // const [localProxyType, setLocalProxyType] = useState(null);
  const [sortParams, setSortParams] = useState(null);
  const [countryParams, setCountryParams] = useState({ id: "", code: "" });

  useEffect(() => {
    if (activeData?.proxy?.code) {
      setLocalProxyType(activeData.proxy.code);
      setCountryParams({ id: "", code: "" });
    }
    // eslint-disable-next-line
    }, [activeData?.proxy?.code]);

  useEffect(() => {
    if (activeData?.proxy?.code) {
      setLocalProxyType(activeData.proxy.code);
    }

    if (activeData?.country?.id) {
      setCountryParams(activeData.country);
    }
    // eslint-disable-next-line
  }, [activeData?.country?.id]);

  useEffect(() => {
    if (s === "prices" && refPrices?.current?.clientHeight) {
      refPrices.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [s]);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <section
      ref={refPrices}
      className={classNames("prices", { "prices--dark": isThemeDark })}
      id="prices"
    >
      <Top
        countryParams={countryParams}
        localProxyType={localProxyType}
        setLocalProxyType={setLocalProxyType}
        setSortParams={setSortParams}
        setCountryParams={setCountryParams}
      />
      {localProxyType === "MOBILE" ? (
        <MobileProxy sortParams={sortParams}/>
      ) : (
        <>
          {isClient && (
            <Countries
              proxyCode={localProxyType}
              localCountry={countryParams}
              setLocalCountry={setCountryParams}
              sortParams={sortParams}
            />
          )}
          <PriceItemsList
            key={countryParams.id}
            proxyCode={localProxyType}
            localCountry={countryParams}
          />
        </>
      )}
    </section>
  );
};
